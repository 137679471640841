<template>
  <SettingsPage>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <div class="block">
      <label class="label">Account Balance</label>
      <p>{{ USD(currentUser.balance).format() }}</p>
    </div>

    <b-table
      :data="transactions"
      :loading="loading"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      default-sort="inserted_at"
      default-sort-direction="desc"
    >
      <b-table-column
        field="inserted_at"
        label="Date"
        sortable
        v-slot="props"
      >
        {{ new Date(props.row.inserted_at).toLocaleDateString() }} {{ new Date(props.row.inserted_at).toLocaleTimeString() }}
      </b-table-column>

      <b-table-column
        label="Organization"
        v-slot="props"
      >
        <span
          v-if="props.row.organization"
        >
          <strong>{{ props.row.organization.name }}</strong><br />
          {{ props.row.location.formatted_address }}
        </span>
        <span
          v-else-if="props.row.charity"
        >
          {{ props.row.charity.name }}
        </span>
      </b-table-column>

      <b-table-column
        label="Deal"
        v-slot="props"
      >
        <a
          v-if="props.row.deal && props.row.deal.is_active"
          @click="goToDeal(props.row)"
        >
          {{ props.row.deal.description }}
        </a>
        <span
          v-else-if="props.row.deal"
        >
          {{ props.row.deal.description }}
        </span>
        <span
          v-else
        >
          n/a
        </span>
      </b-table-column>

      <b-table-column
        field="category"
        label="Type"
        sortable
        v-slot="props"
      >
        {{ props.row.category }}
      </b-table-column>

      <b-table-column
        field="status"
        label="Status"
        sortable
        v-slot="props"
      >
        {{ props.row.status }}
      </b-table-column>

      <b-table-column
        field="amount"
        label="Amount"
        sortable
        v-slot="props"
      >
        {{ USD(transactionAmount(props.row)).format() }}
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">
          No transaction history
        </div>
      </template>
    </b-table>
  </SettingsPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors/index.js"
import SettingsPage from "@/components/SettingsPage.vue"
import Notification from "@/components/Notification.vue"

export default {
  components: {
    Notification,
    SettingsPage,
  },
  data() {
    return {
      error: null,
      loading: false,
      transactions: []
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser
    },
  },
  methods: {
    go(path) {
      this.$router.push(path)
    },
    goToDeal(transaction) {
      this.go(`/deal/${transaction.deal.business.slug}/${transaction.deal.slug}/l/${transaction.location.id}`)
    },
    transactionAmount(transaction) {
      let amount = 0
      if (transaction.status == "approved") {
        switch (transaction.category) {
          case "click":
          case "scan":
            amount = transaction.amount
            break
          case "payout":
          case "donation":
            amount = transaction.amount * -1
            break
        }
      }
      return amount
    },
    updateTransactions() {
      if (this.loading) {
        return
      }

      this.error = null
      this.loading = true
      this.$store.dispatch("getTransactions", this.currentUser.slug)
        .then(resp => {
          this.transactions = resp
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.updateTransactions()
  }
}
</script>
